import { post, deletes, get, downFile } from 'common/request'
export default {
  /**
   * 获取
   */
  // 获取常住人口列表数据
  list: params => get('/residentArchives/list', params),
  
  /**
     * 字典
     */
  // form里面的下拉获取数据字典
  dictData: args => get('/dict-data/getByDicKind', args),
}
